//import Vue from 'vue'
//import Vuex from 'vuex'

import { createStore } from 'vuex'
import {getField, updateField} from 'vuex-map-fields'

//Vue.use(Vuex)
//export const store = new Vuex.Store({
export const store = createStore({
    state:{
        //general
        online:true,
        
        username:'',
        oldpassword:'',
        password:'',
        token:null,
        loggedInUser:{
            name:'User',
            user_personId:0,
            LocationId:0,
            isAdmin:false
        },
        adAccount:null,
        showHeader:true,
        loggedIn:false,
        securityAppName:'TimeClock',
        robot:true,

        currentNav:null,
        
        pleaseWait:false,
        responseMessage:'',


    

        msalConfig:{
            auth:{
                clientId:window.AD_CLIENTID,
                authority:window.AD_AUTHORITY
            },
            cache:{
                cacheLocation:'localStorage',
            },
            accessToken:''
        },
       

    },
    mutations:{
        updateField,
        resetbeforeapi(state){
            state.pleaseWait=true;
            state.responseMessage=''
        },
        setAccessToken(state, token){
            state.accessToken=token
        }
    },
    getters:{
        getField,
    },
    actions:{
        beforeAPI(context){
            context.commit('resetbeforeapi'); 
        },
        
    }
})