
<template>
    
    <div class="outerDiv" :style="configStyle">
      <navheader/>
  
      <div class="mainDivAdmin">
        <img alt="school logo" :src="schoolLogoFilePath"  class="logotop" >
        <loginuser  ref='loginuser' v-if="!loggedIn" />
        <div v-if="loggedIn">
         
            
         <scan/>
        
        </div>
  
      </div>

    </div>
  </template>
  
  <script>
  import loginuser from './login/loginuser.vue'
  import datamethods from '../services/datamethods'
  import {mapFields} from 'vuex-map-fields'
  import style from '../style.js'
  import navheader from './navheader.vue'
import scan from './scan.vue'
  export default {
    name: 'Start',
    components:{
      loginuser,
      navheader,
      scan
    },
    mixins:[datamethods,style],
     data(){
          return{
            
            currentTime:'',
            trueParam:true,
            
    
          }
     },
     computed:{
          ...mapFields([
              'loggedIn',
              'username',
              'oldpassword',
              'loggedInUser',
              'loggedInUser.isAdmin',
              'logoOverride',
              'token',
              'showHeader',
              'currentNav',
              'adAccount',
     

          ]),
          schoolLogoFilePath(){
            
            return './../' + window.SCHOOL_LOGO

        },
      
      },
     methods:{
        
        logout(){
           
            this.loggedIn=false;
        },
        receiveMessage(event){
          var msg =""
        try{
            msg=JSON.parse(event.data)
        }catch(e){
            
        }

          if(msg!=undefined){
            var action = msg.action
              if(action !=undefined){
              
                  console.log("Eval Receive Msg: " + action)
                  switch (action) {
                      case 'refresh':
                      
        
          
        
                          break;
                  
                      default:
                          break;
                  }
                  
              }
          }
        },
       
     },
    
     mounted(){

      this.currentNav='time'
      
      this.setColors()
      window.addEventListener('message', this.receiveMessage)
  
      let urlParams = new URLSearchParams(window.location.search)
      let tmp = urlParams.get('email')
      if(tmp!=null){
          this.username = tmp;
      }
  
      let tmp2 = urlParams.get('temp')
      if(tmp2!=null){
          this.oldpassword = tmp2;
          this.$nextTick(()=>{
          //this.changepass()
          this.$refs.loginuser.changepassword();
          });
      }
      tmp = urlParams.get('header')
      if(tmp!=null){this.showHeader=tmp=='true'; }
  
      tmp = urlParams.get('token')
      //console.log('token ' + tmp)
      if(tmp!=null){
         // console.log('sso!')
          this.$refs.loginuser.post_login(tmp)
        }

     },
     
     
     

  }
  </script>
  
  <style lang="scss">
  @import "@/assets/stylebase.scss";
  
  body{
    height:100vh; 
    height: -webkit-fill-available;
    width:98.7vw;
    padding:0px;
    margin:0px;
    font-size:medium;
    color:var(--text-color);
  }
  .outerDiv{
    margin:0px;
    padding:0px;
      height:95vh;
      display:grid;
      grid-template-rows:90px auto;
  
  }
  
  .mainDivAdmin{
    
       grid-row:2;
  
      background-color:var(--background-color);
      padding:20px;
  
       
  }
  .logotop{
  margin-top:0px;
  width:400px;
}
  </style>
  