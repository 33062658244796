<template>
   
        <div class="vertFlex scandiv"  v-on:keyup.enter="lookupperson">
          
            <label class="scanlabel">Current Time: {{currentTime}}</label>
            <label class="scanlabel">Student Lookup</label>
            <div class="jobbuttons">
                <input ref="scan" class="scantext" v-model="lookuptext"/>
                <smallbutton toplabel="Go" buttonclass="buttonprimary scanbutton" @click="lookupperson" />
            </div>
           
            <div class="jobbuttons">
              <label class="scanlabel">{{actionmessage}}</label>
              <label class="scanlabel bold">{{actionmessage2}}</label>
            </div>
            

            <div v-if="showactions">
                <div v-if="!isIn">
                    <label class="scanlabel">What are you clocking in to?</label>
                  
                    <div class="jobbuttons">
                        
                       
                        <smallbutton v-for="ej in jobList" :key="ej.id"  :toplabel="ej.name" @click="clockin(ej)"/>
                        
                    
                    </div>
                   
                    
                    
                    
                </div>
                <div v-if="isIn">
                    <smallbutton toplabel="Clock Out" @click="clockout(ej)"/>
                </div>
            </div>
           
        </div>
          
  </template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../services/datamethods.js'
  import securityhelper from '../services/securityhelper'
  import linkbutton from '../components/controls/linkbutton.vue'
  import smallbutton from '../components/controls/button.vue'

  export default {
      name:'scan',
      mixins:[datamethods,securityhelper],
      components:{

          linkbutton,
          smallbutton,

          
      },
      
      computed:{
          ...mapFields([
          'loggedInUser'
  
          ]),
          
      },
      data(){
          return{
             timer:null,
              currentTime:null,
             
              pleaseWait:false,
              responseMessage:'',

              lookuptext:null,

              personName:null,
              isIn:false,
              outJobId:-1,

              showactions:false,
              jobList:[
                {
                    id:1,
                    name:'Job 1'

                },
                {
                    id:2,
                    name:'Job 2'
                }
              ],
              actionmessage:null,
              actionmessage2:null


              
          }
      },
      methods:{
        updateTime(){
          var t= new Date();

            var hh=String(t.getHours()).padStart(2, '0');
            var nn= String(t.getMinutes()).padStart(2, '0');
            
            this.currentTime= hh+ ':' + nn 
        },
        resetfocus(){
          this.lookuptext=null
        
          this.$nextTick(() => {
              this.$refs.scan.focus()
          })
        },
        async lookupperson(){
          
           /*  if(this.lookuptext=='1'){
            this.personName='Joe Shmo'
            this.actionmessage2= 'OUT'
            this.showactions=true
            this.isIn=false
            return
          }else if(this.lookuptext=='2'){
            this.personName='Jane Doe'
            this.actionmessage2= 'IN'
            this.showactions=true
            this.isIn=true
            return
          }  */
            let req = {
                lookupString:this.lookuptext
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'lookupfortimeclock')
            //handle response
            if(response.Successful){
                if(response.name==null){
                    this.actionmessage = "Student Not Found"
                    this.showactions=false
                    return
                }
                 this.personName = response.name
                 this.personId=response.personId
                 this.isIn=response.isIn
                 this.jobList= response.jobList
                 this.showactions=true

                 this.actionmessage = this.personName + ' is clocked '
                 if(this.isIn){
                    this.actionmessage2= 'IN'
                    this.outJobId = this.jobList[0].jobId_Course
                 }else{
                    this.actionmessage2= 'OUT'
                 }
              
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          
          
           /*  console.log('lookuperson')
          if(this.lookuptext=='1'){
            this.personName='Joe Shmo'
            this.actionmessage= this.personName + ' is OUT'
            this.showactions=true
            this.isIn=false
          }else if(this.lookuptext=='2'){
            this.personName='Jane Doe'
            this.actionmessage=this.personName + ' is IN'
            this.showactions=true
            this.isIn=true
          } */
        },
        async clockin(ej){
            let req = {
                personId:this.personId,
                jobId:ej.jobId_Course,
                personId_CheckedInBy: this.loggedInUser.user_personId,
                locationId:this.loggedInUser.LocationId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'timeclock_clockin')
            //handle response
            if(response.Successful){
                this.actionmessage="Thank you " + this.personName + " for clocking into " + ej.name
              this.actionmessage2=null
                this.showactions=false
                this.resetfocus()
              
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 


            
        },
        async clockout(){
            let req = {
                personId:this.personId,
                jobId:this.outJobId,
                personId_CheckedInBy: this.loggedInUser.user_personId,
                locationId:this.loggedInUser.LocationId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'timeclock_clockout')
            //handle response
            if(response.Successful){
                this.actionmessage="Thank you " + this.personName + " for clocking out " 
                this.actionmessage2=null
                this.showactions=false
                this.resetfocus()
              
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        }

      },
      mounted(){
        this.updateTime()
        this.timer = setInterval(() => {
          this.updateTime()
        }, 15000)
        this.resetfocus()

      },
      beforeUnmount(){
        this.timer=null;
      }
  }
  </script>
  
  <style>
  .scantext{
    font-size:x-large;
    margin:20px 20px 20px 20px;
  }
  .scanlabel{
    font-size:x-large;
    margin:20px 7px 10px 0px;
  }
  .scanbutton{
    height:32px;
    width:40px;
    border:none;
    border-radius: 2px;
    margin-top:20px;
    font-size:large;
  }
  .scandiv{
    margin-top:120px;
  }
  .jobbuttons{
  
    display:flex;
    flex-direction: row;
    justify-content: center;
    margin-top:20px;
 
  }
  </style>
  
  
   